import { html, render } from 'lit-html';
import { Loader } from './ui.loader.js';
import { GetBaseUrl } from './utils.get-base-url.js';
import i18n from './outbook-rest.i18n.js';
export function imageBing({ toolPlaceholder }) {
  function getTemplate({ model, fetched }) {
    return fetched
      ? html`
          <p class="margin-bottom-s text-size-1xl">${model.headline[0]}</p>
          <p class="margin-bottom-s">
            <a href="${model.url}" target="_blank">
              <img
                src="${model.url}"
                alt="${model.headline[0]} (${i18n.imageBing.newWindow})"
                aria-hidden="true"
                class="width-full"
              />
            </a>
          </p>
          <p class="text-size-m">
            <a href="${model.search[0]}" target="_blank"
              >${model.copyright[0]} (${i18n.imageBing.newWindow})</a
            >
          </p>
        `
      : html`<div class="flex flex-justify-center">${Loader()}</div>`;
  }
  function renderTemplate(params) {
    render(getTemplate(params), toolPlaceholder);
  }
  renderTemplate({ model: null, fetched: false });
  fetch(`${GetBaseUrl()}/image-of-the-day`)
    .then((response) => response.json())
    .then((model) => {
      renderTemplate({ model, fetched: true });
    });
}
