import { html, render } from 'lit-html';
import { Button } from './ui.component.button.js';
import { getContrast } from './utils.wcag2-contrast.js';
import i18n from './outbook-rest.i18n.js';
import { IconText } from './ui.icon-text.js';
import { v4 as uuid } from 'uuid';
import { InputColor } from './ui.input-color.js';
import { validateHexadecimal } from './utils.validate-hexadecimal.js';
import { formatString } from '../../../../packages/base-assets/js/utils.format-string.js';
import { classMap } from 'lit/directives/class-map.js';

const TEXT_SIZE = {
  small: i18n.wcag2Contrast.cases.size.small,
  large: i18n.wcag2Contrast.cases.size.large
};

const isInputColor = true;

export function wcagContrast({ toolPlaceholder }) {
  const model = {
    inputForeground: {
      uuid: uuid(),
      color: `${isInputColor ? '#' : ''}000000`,
      isValid: true,
      label: 'Color de texto'
    },
    inputBackground: {
      uuid: uuid(),
      color: `${isInputColor ? '#' : ''}FFFFFF`,
      isValid: true,
      label: 'Color de fondo'
    },
    showResult: true
  };

  function validate(input) {
    return function (ev) {
      input.isValid = validateHexadecimal(ev.currentTarget.value);
      input.color = ev.currentTarget.value;
      model.showResult =
        model.inputForeground.isValid && model.inputBackground.isValid;
      renderTemplate();
    };
  }

  function getTemplate() {
    const TEXT_ITEMS = getContrast([
      model.inputForeground.color,
      model.inputBackground.color
    ]);
    return html`
      <div class="flex flex-wrap">
        <div
          class="margin-bottom-2xs width-size-full s:padding-2xs m:padding-1xs"
        >
          <div class="border border-color-grey_200 padding-1xs">
            <div class="s:flex flex-space-self-all margin-bottom-1xs">
              ${[model.inputForeground, model.inputBackground].map(input => {
                return html`
                  <div
                    class="margin-bottom-s s:margin-bottom_0 s:width-size-half s:margin-right-2xs m:width-size-half m:margin-right-2xs"
                  >
                    ${InputColor({
                      value: input.color,
                      isValid: input.isValid,
                      uuid: input.uuid,
                      label: input.label,
                      fnChange: validate(input),
                      isInputColor
                    })}
                  </div>
                `;
              })}
            </div>
            <div class="flex flex-justify-center">
              ${Button({
                text: 'Comprobar',
                icon: 'replay',
                eventFn: function () {
                  if (
                    model.inputForeground.isValid &&
                    model.inputBackground.isValid
                  ) {
                    model.showResult = true;
                    renderTemplate();
                  }
                }
              })}
            </div>
          </div>
        </div>
        <div
          class="margin-bottom-2xs width-size-full s:padding-2xs m:padding-1xs"
        >
          <div class="border border-color-grey_200 padding-1xs">
            ${model.showResult
              ? html`
                  ${TEXT_ITEMS.results.map((item, index) => {
                    const sizePx = item.sizeNum * 1.333;
                    const regionId = `region-${uuid()}-label`;
                    const resultClasses = {
                      'margin-top-s padding-top-m border-top border-color-grey_200':
                        index > 0
                    };
                    return html`
                      <div
                        class="${classMap(resultClasses)}"
                        role="region"
                        aria-labelledby="${regionId}"
                      >
                        <p
                          class="margin-bottom-1xs text-size-1xs"
                          id="${regionId}"
                        >
                          ${formatString(i18n.wcag2Contrast.cases.title, [
                            item.level,
                            TEXT_SIZE[item.size],
                            item.required
                          ])}
                        </p>
                        <p class="margin-bottom-1xs">
                          ${IconText({
                            text: `${
                              i18n.wcag2Contrast.contrast
                            } ${TEXT_ITEMS.calculatedContrast.toFixed(2)} ${
                              item.compliant
                                ? i18n.wcag2Contrast.cases.result.valid
                                : i18n.wcag2Contrast.cases.result.notValid
                            }`,
                            icon: {
                              icon: item.compliant ? 'check' : 'error',
                              color: item.compliant ? 'green_600' : 'red_600'
                            }
                          })}
                        </p>
                        <div
                          class="padding-2xs border border-color-grey_100 text-weight-${item.class}"
                          style="font-size: ${sizePx}px; color:${isInputColor
                            ? ''
                            : '#'}${model.inputForeground
                            .color}; background-color: ${isInputColor
                            ? ''
                            : '#'}${model.inputBackground.color}"
                        >
                          Text ${item.sizeNum}pt (${sizePx}px) ${item.weight}
                        </div>
                      </div>
                    `;
                  })}
                `
              : ''}
          </div>
        </div>
      </div>
    `;
  }

  function renderTemplate() {
    render(getTemplate(), toolPlaceholder);
  }
  renderTemplate();
}
