import { html, render } from 'lit-html';
import {
  isEventClick as isClick,
  isKeyEnterOrKeySpace as isEnterOrSpace,
  isKeyEscape as isEscape,
  selectorFosusables as selectorFosusablesAll
} from 'a11y-key-conjurer';
import { Loader } from './ui.loader.js';
// import { Validation } from '../../store/js/app.validation.js';
import i18n from './common.i18n.js';
import { v4 as uuid } from 'uuid';

function templateFocusTrap({ position, templateId }) {
  return html`
    <div tabindex="0" @focus="${trapFocus(position, templateId)}"></div>
  `;
}

function getTemplate({ content, handleClose, showButtonClose }) {
  const templateId = `dialog-${uuid()}`;
  return html`
    <div
      class="position-fixed top_0 left_0 bg-color-black dark:bg-color-grey_500 opacity_50 z-index_2 width-full height-viewport"
    ></div>
    <div
      class="position-fixed top_0 left_0 z-index_2 flex flex-justify-center flex-align-center padding-1xs m:padding-3xl width-full height-viewport"
    >
      ${templateFocusTrap({ position: 'start', templateId })}
      <div
        class="bg-color-white dark:bg-color-grey_600 container--dialog min-height-size-four-fifths border-radius padding-1xs padding-top-m position-relative flex flex-column"
        id="${templateId}"
      >
        <div class="position-absolute top-2xs right-2xs">
          ${showButtonClose
            ? html`
                <div
                  class="link outline text-size-1xs ${showButtonClose
                    ? ''
                    : 'hidden'}"
                  tabindex="0"
                  role="button"
                  data-close
                  id="modal-dialog-close"
                  @click="${handleClose}"
                  @keydown="${handleClose}"
                >
                  ${i18n.modalDialog.buttonClose}
                </div>
              `
            : ''}
        </div>
        <div
          class="height-full width-full overflow-y-auto"
          id="modal-dialog-content"
        >
          ${content}
        </div>
      </div>
      ${templateFocusTrap({ position: 'last', templateId })}
    </div>
  `;
}

function trapFocus(position, templateId) {
  return function () {
    const container = document.getElementById(templateId);
    let focusableElement;
    if (position === 'start') {
      const focusables = Array.from(
        container.querySelectorAll(selectorFosusablesAll)
      );
      focusableElement = focusables[focusables.length - 1];
    } else {
      focusableElement = container.querySelector(selectorFosusablesAll);
    }
    focusableElement.focus();
  };
}

function setEvents({ placeholder, originTarget, pageElement }) {
  function escapeDialog(ev) {
    if (isEscape(ev)) {
      ev.preventDefault();
      closeDialog({
        placeholder,
        originTarget,
        pageElement,
        escapeDialog
      });
    }
  }
  /*Validation({
    rootElement: placeholder
  });*/
  document.body.addEventListener('keyup', escapeDialog);
}

function closeDialog({ placeholder, originTarget, pageElement, escapeDialog }) {
  document.body.removeEventListener('keyup', escapeDialog);
  render(html``, placeholder);
  pageElement.removeAttribute('tabindex');
  pageElement.removeAttribute('aria-hidden');
  document.body.classList.remove('overflow-y-hidden');
  originTarget.focus();
}

export function openDialog(ev, options) {
  if (isClick(ev) || isEnterOrSpace(ev)) {
    ev.preventDefault();
    const originTarget = ev.currentTarget;
    const pageElement = document.getElementById('page-area');
    const placeholder = document.getElementById('placeholder-modal-dialog');
    const handleClose = ev => {
      if (isClick(ev) || isEnterOrSpace(ev)) {
        ev.preventDefault();
        closeDialog({ placeholder, originTarget, pageElement });
      }
    };
    render(
      getTemplate({
        content: html`
          <div
            class="flex flex-justify-center position-absolute top-center left-center"
          >
            <p class="text-size-l" role="alert">${Loader()}</p>
          </div>
        `,
        handleClose,
        showButtonClose: false
      }),
      placeholder
    );
    pageElement.setAttribute('tabindex', '-1');
    pageElement.setAttribute('aria-hidden', 'true');
    document.body.classList.add('overflow-y-hidden');
    if (typeof options.actionOpen === 'function') {
      options.actionOpen(function ({ htmlContent }) {
        setTimeout(() => {
          render(
            getTemplate({
              content: htmlContent,
              handleClose,
              showButtonClose: true
            }),
            placeholder
          );
          const buttonClose = placeholder.querySelector('[data-close]');
          buttonClose.focus();
          setTimeout(() => {
            setEvents({ originTarget, pageElement, placeholder });
          }, 100);
        }, 0);
      });
    }
  }
}
